import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';



import Login from './routes/login';
import NewAdmin from './routes/newAdmin';

import Home from './routes/home';
import Users from './routes/users';
import Wallet from './routes/setWallet';
import Account from './routes/account';




const router = createBrowserRouter([
    {
        path : "/",
        element : <Home />
    },
    {
        path : "/login",
        element : <Login />
    },
    {
        path : "/new-admin",
        element : <NewAdmin />
    },
    {
        path : "/home",
        element : <Home />
    },
    {
        path : '/users',
        element : <Users />
    },
    {
        path : '/set-wallet',
        element : <Wallet />
    },
    {
        path : '/admin',
        element : <Account />
    },

])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
