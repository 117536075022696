import {createContext, useContext, useState } from "react";

import Admin from "./admin";

const LocalContext = createContext();
export const AdminContext = () => useContext(LocalContext);

export const AdminProvider = ({children, AId}) => {
    const [admin] = useState(new Admin(AId));

    return(
        <LocalContext.Provider value={{admin}}>
            {children}
        </LocalContext.Provider>
    )
}