import { Link } from "react-router-dom";
import { Profile } from "../svg";

const Header = () =>{
    return(
        <header className="header">
            <div className="org-id">
                <img src="/assets/images/logo.png" id="logo"/>
                <p><span>SPIN</span><span>BONANZA</span></p>
            </div>
            <Link>
                <Profile />
            </Link>
        </header>
    )
}

export default Header;