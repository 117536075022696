
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AdminContext } from "../models/admin";

import Layout from "../components/layout";


import '../styles/home.css';
const Content = () =>{
    const [usersCount, setUsersCount] = useState(0);

    let Client = AdminContext().admin;

    

    useEffect(()=>{
        const getUsersNumber = async () =>{
            Client.getBasicDetailsOfUsers((users)=>{
                if(users){
                    setUsersCount(users.length);
                }
            });
        }
        getUsersNumber();
    },[])

    return(
        <div className="home-main">
            <div className="users-splash">
                <p>{usersCount}</p>
                <p>Total Users</p>
            </div>
            <div className="home-actions">
                <Link to="/users">Users</Link>
                <Link to="/set-wallet">Set Wallet</Link>
                {/* <Link to="/admin">Acount</Link> */}
            </div>
        </div>
    )
}
const Home = () =>{
    return(
        <Layout>
            <Content />
        </Layout>
    )
}

export default Home;