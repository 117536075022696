import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

import config from '../config';
import { loadEnd, loadStart } from "../utils";

import '../styles/auth.css'

const Login = () =>{
    const navigate = useNavigate();
    loadEnd();
    return(
        <main className="auth-main">
            <div className="auth-container">
                <img src="/assets/images/logo.png" className="auth-logo" alt=''/>
                <p className="auth-header">Admin Login</p>
                <div className="auth-form-main">
                    <div>
                        <p className="auth-label">User Name</p>
                        <input type="text" className="auth-input" id="login-username"/>
                    </div>
                    <div>
                        <p className="auth-label">Password</p>
                        <input type="password" className="auth-input" id="login-password"/>
                    </div>
                    <div>
                        <button className="brdls auth-submit-btn" onClick={()=>{
                            let fd = new FormData();
                            fd.append('user',$('#login-username').val());
                            fd.append('pass',$('#login-password').val());
                            loadStart();
                        $.ajax({
                            url : `${config.apiUrl}/api/v1/auth/login-admin`,
                            dataType : 'json',
                            method : "POST",
                            data : fd,
                            dataType : 'json',
                            processData : false,
                            contentType: false,
                            cache: false
                        })
                        .done(data=>{
                            loadEnd();
                            alert(data.message);
                            if(data.status == 0){
                                window.localStorage.setItem('awt',data.token);
                                
                                // window.location.href = "/";
                                navigate('/');
                                
                            }
                        })
                        .fail(err=>{
                            loadEnd();
                            alert('An error occured. Please try again');
                            console.log(err);
                        })
                    
                }}>Login</button>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Login;