import { useEffect, useState } from "react";

import { AdminContext } from "../models/admin";

import Layout from "../components/layout";

import "../styles/wallet.css";

const Content = () =>{
    const [wa, setWa] = useState([]);
    const [add, setAdd] = useState(false);
    const [del, setDel] = useState(false);
    const [edit, setEdit] = useState(false);

    const [currWallet, setCurrWallet] = useState();

    const Client = AdminContext().admin;

    const TriggerPullData = () =>{
        Client.getWallets(data=>{
            console.log(data);
            if(data.status == 0){
                let _tmp = [];
                data.data.forEach(el => {
                    _tmp.unshift(<Entry data={{
                        address : el.address,
                        network : el.network,
                        coin : el.coin
                    }} />)
                });
                setWa(_tmp);
            } else {
                alert(data.message);
            }           
        },err=>{
            alert('An error occured. Please try again');
            console.log(err);
        })
    }

    useEffect(()=>{
        TriggerPullData();
    },[Client])
    
    const Add = () =>{
        const [address, setAddress] = useState();
        const [psk, setPsk ] = useState();
        const [coin, setCoin] = useState();
        const [network, setNetwork] = useState();
        const [allowProceed, setAllowProceed] = useState(false);


        useEffect(()=>{
            if(coin && psk && network && address) setAllowProceed(true);
            else setAllowProceed(false);
        },[coin,address,psk,network])

        return(
            <div className="ov-base">
                <div className='ov-cont'>
                    <p className="ew-head">Add new wallet</p>
                    <div className="ew-body">

                        <div className="ew-inp">
                            <p>Address</p>
                            <input type="text" className="brdls" onChange={(e)=>{
                                setAddress(e.target.value);
                            }}/>
                        </div>

                        <div className="ew-inp">
                            <p>Coin</p>
                            <select  className="brdls ew-select" onChange={(e)=>{
                                setCoin(e.target.value);
                            }}>
                                <option value="">-select coin-</option>
                                <option value="USDT">USDT</option>
                                <option value="BNB">BNB</option>
                                <option value="BTC">BTC</option>
                            </select>
                        </div>

                        <div className="ew-inp">
                            <p>Network</p>
                            <select  className="brdls ew-select" onChange={(e)=>{
                                setNetwork(e.target.value);
                            }}>
                                <option value="">-select network-</option>
                                <option value="Trc20">Trc20</option>
                                <option value="Bep20">Bep20</option>
                            </select>
                        </div>

                        <div className="ew-inp">
                            <p>Passkey</p>
                            <input type="number" className="brdls" onChange={(e)=>{
                                setPsk(e.target.value);
                            }}/>
                        </div>
                    </div>
                    <div className="ew-actions">
                        <div className="ew-req-actions">
                            <button className="brdls" style={{backgroundColor:'var(--txt-dim)'}} disabled={!allowProceed} onClick={()=>{
                                let fd = new FormData();
                                fd.append('addr',address);
                                fd.append('coin',coin);
                                fd.append('network',network);
                                fd.append('psk',psk);
                                Client.addWallet(fd,data=>{
                                    alert(data.message);
                                    if(data.status == 0){
                                        TriggerPullData();
                                        setAdd(false);
                                    }
                                    console.log(data);
                                }, err=>{
                                    console.log(err);
                                })
                            }}>Add</button>
                        </div>
                        <button className="cancel-ew brdls" onClick={()=>{
                            setAdd(false);
                        }}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    const Delete = () =>{
        const [psk, setPsk ] = useState();
        const [allowProceed, setAllowProceed] = useState(false);

        useEffect(()=>{
            if(psk) setAllowProceed(true);
            else setAllowProceed(false);
        })

        return(
            <div className="ov-base">
                <div className='ov-cont'>
                    <p className="ew-head">Delete Wallet Address {currWallet}</p>
                    <div className="ew-body">
                        <div className="ew-inp">
                            <p>Passkey</p>
                            <input type="number" className="brdls" onChange={(e)=>{
                                setPsk(e.target.value);
                            }}/>
                        </div>
                        <div className="ew-actions">
                        <div className="ew-req-actions">
                            <button className="brdls" disabled={!allowProceed} onClick={()=>{
                                let fd = new FormData();
                                fd.append('addr',currWallet);
                                fd.append('psk',psk)
                                Client.deleteWallet(fd,data=>{
                                    alert(data.message);
                                    if(data.status == 0){
                                        TriggerPullData();
                                        setDel(false);
                                    }
                                    console.log(data);
                                }, err=>{
                                    console.log(err);
                                })
                            }}>Delete</button>
                        </div>
                        <button className="cancel-ew brdls" onClick={()=>{
                            setAdd(false);
                        }}>Cancel</button>
                    </div>
                    </div>
                </div>
            </div>

        )
    }

    const Entry = ({data}) =>{
        return(
            <div className="wa-entry">
                <span>{data.address}</span>
                <span>{data.coin}</span>
                <span>{data.network}</span>
                <div>
                    <button className="wa-action-btn brdls" onClick={()=>{
                        setCurrWallet(data.address);
                        setDel(true);
                    }}>Delete</button>
                    {/* <button className="wa-action-btn brdls" >Edit</button> */}
                </div>
            </div>
        )
    }

    return(
       <>
       <div className="wallet-main">
            <div className="wa-head">
                App Wallets
            </div>
            <div className="wa-cont">
                <div className="wa-t">
                    <div className="wa-t-head">
                        <span>Address</span>
                        <span>Coin</span>
                        <span>Network</span>
                        <span>Actions</span>
                    </div>
                    <div className="wa-t-body">
                        {wa}
                    </div>
                </div>
            </div>
       </div>
       {add && <Add />}
       {del && <Delete />}
       <button className="brdls rfsh-table" onClick={()=>{
            setAdd(true);
       }}>Add New Wallet</button>
       </>
    )
}

const Wallet = () =>{
    return(
        <Layout>
            <Content />
        </Layout>
    )
}

export default Wallet;