import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { logout_user } from "../../utils";

import { Logout, Menu } from "../svg";

const Nav = () =>{

    const [location, setLocation] = useState();
    const [menuOpen, OpenMenu] = useState(false);
    const paths = useLocation().pathname.split('/').filter(Boolean);

    useEffect(()=>{
        switch (paths[0]) {
            case undefined:
            case 'home':
                setLocation(0);
                break;
            case 'users':
                setLocation(1);
                break;
            case 'set-wallet':
                setLocation(2);
                break;
            // case 'admin':
            //     setLocation(3);
            //     break;
            default:
                setLocation(0);
                break;
        }
    })

    return(
        <nav className={`${menuOpen?'active':''} nav`}>
             <button id="menu-open-btn" className="brdls mobile-item" onClick={()=>{
                OpenMenu(!menuOpen);
            }}>
                <Menu />
            </button>
            <div className="body">
                <Link className={`nav-btn ${location==0?'active':''}`} to="/" onClick={()=>{
                    OpenMenu(!menuOpen);
                }}>
                    <span>HOME</span>
                </Link>
                <Link className={`nav-btn ${location==1?'active':''}`} to="/users" onClick={()=>{
                    OpenMenu(!menuOpen);
                }}>
                    <span>USERS</span>
                </Link>
                <Link className={`nav-btn ${location==2?'active':''}`} to="/set-wallet" onClick={()=>{
                    OpenMenu(!menuOpen);
                }}>
                    <span>SET WALLET</span>
                </Link>
                {/* <Link className={`nav-btn ${location==3?'active':''}`} to="/admin" onClick={()=>{
                    OpenMenu(!menuOpen);
                }}>
                    <span>ACCOUNT</span>
                </Link> */}
            </div>

            <div className="end">
                <Link className="nav-btn" onClick={()=>{
                    OpenMenu(!menuOpen);
                    logout_user();
                }}>
                    <Logout />
                </Link>
            </div>
        </nav>
    )
}

export default Nav;