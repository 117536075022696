

import { AdminProvider } from "../../models/admin";
import { loadEnd } from "../../utils";

import Header from "./header";

import "../../styles/layout.css";
import Nav from "./nav";

let authenticated = false;
if(localStorage.getItem('awt')){
    authenticated = true;
}


const Provider = ({ children }) =>{
    if(authenticated){
        return(
            <AdminProvider AId={localStorage.getItem('awt')}>
                {children}
            </AdminProvider>
        )
    }
    else{
        window.location.href = '/login';
        return(
            <>
            {children}
            </>
        )
    }
}

const Layout = ({ children }) => {
    loadEnd();
    return(
        <Provider>
            <main className="layout-main">
                <Header />
                <div className="layout-base">
                    <Nav />
                    <div className="content">
                        { children }
                    </div>
                </div>
                
            </main>
        </Provider>
        
    )
}

export default Layout;