
import Layout from "../components/layout";

const Account = () =>{
    return(
        <Layout>
            
        </Layout>
    )
}

export default Account;