import $ from 'jquery';

import config from '../config';

import { loadEnd, loadStart } from "../utils";


import '../styles/auth.css'
const NewAdmin = () =>{
    loadEnd();
    return(
        <main className="auth-main">
            <div className="auth-container">
                <img src="/assets/images/logo.png" className="auth-logo" alt=''/>
                <p className="auth-header">New Admin</p>
                <div className="auth-form-main">
                    <div>
                        <p className="auth-label">User Name</p>
                        <input type="text" className="auth-input" id="new-login-username"/>
                    </div>
                    <div>
                        <p className="auth-label">Password</p>
                        <input type="text" className="auth-input" id="new-login-password"/>
                    </div>
                    <div>
                        <p className="auth-label">Pass key</p>
                        <input type="password" className="auth-input" id="new-login-passkey"/>
                    </div>
                    <div>
                        <button className="brdls auth-submit-btn" onClick={()=>{
                            let fd = new FormData();
                            // console.log($('#login-username').val());
                            fd.append('user',$('#new-login-username').val());
                            fd.append('pass',$('#new-login-password').val());
                            fd.append('psk',$('#new-login-passkey').val());
                            loadStart();
                        $.ajax({
                            url : `${config.apiUrl}/api/v1/auth/create-admin`,
                            dataType : 'json',
                            method : "POST",
                            data : fd,
                            dataType : 'json',
                            processData : false,
                            contentType: false,
                            cache: false
                        })
                        .done(data=>{
                            loadEnd();
                            console.log(data);
                            alert(data.message);
                            if(data.status == 0){
                                window.location.href = '/login'
                            }
                        })
                        .fail(err=>{
                            loadEnd();
                            alert('An error occured. Please try again');
                            console.log(err);
                        })
                    
                }}>Create New Admin</button>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NewAdmin;