import $ from 'jquery';
import config from '../../config';
import { logout_user, loadStart, loadEnd } from '../../utils';

class Admin {
    constructor(userId){
        this.User_key = userId;
        // this.init();
    }

    _fetch = async(_url,payload,cb,cbe) =>{
        let token;
        if(this.User_key){
            token = this.User_key;
        }
        $.ajax({
            url : `${config.apiUrl}/${_url}`,
            dataType : 'json',
            method : 'post',
            headers: {
                'Authorization': `Bearer ${this.User_key}`
            },
            data : payload,
            processData : false,
            contentType: false,
            cache: false
        }).done((data) =>{
            if(data.status == 79){
                alert('Session expired. Relogin to continue');
                window.location.href = '/login';
            }
            cb(data);
        }).fail((err)=>{
            cbe(err);
        });
    }

    getBasicDetailsOfUsers = async(cb) =>{
        loadStart();
        this._fetch('api/v1/admin/users',new FormData,data=>{
            loadEnd();
            if(data.status == 0){
                cb(data.users);
            }
            else if(data.message) alert(data.message)
            cb(null);
        },err=>{
            alert('unknown error. Please try again');
            console.log(err);
            loadEnd();
            cb(null)
        })
    }

    updateUserBalance = async(fd,cb,cbe) =>{
        loadStart();
        this._fetch('api/v1/admin/user-balance',fd,data=>{
            loadEnd();
            cb(data)
        },err=>{
            loadEnd();
            cbe(err);
        })
    }

    getUserHistory = async(fd,cb,cbe) =>{
        loadStart();
        this._fetch('api/v1/admin/user-history',fd,data=>{
            loadEnd();
            cb(data);
        },err=>{
            loadEnd();
            cbe(err);
        })
    }



    deleteUser = async(fd,cb,cbe) =>{
        loadStart();
        this._fetch('api/v1/admin/delete-user',fd,data=>{
            loadEnd();
            cb(data);
        }, err => {
            loadEnd();
            cbe(err);
        })
    }

    addWallet = async(fd,cb,cbe) =>{
        loadStart();
        this._fetch('api/v1/admin/new-wallet',fd,data=>{
            loadEnd();
            cb(data);
        }, err =>{
            loadEnd();
            cbe(err);
        })
    }

    getWallets = async(cb, cbe) =>{
        loadStart();
        this._fetch('api/v1/admin/wallets',new FormData,data=>{
            loadEnd();
            cb(data);
        }, err =>{
            loadEnd();
            cbe(err);
        })
    }

    deleteWallet = async(fd, cb, cbe) =>{
        loadStart();
        this._fetch('api/v1/admin/delete-wallet',fd,data=>{
            loadEnd();
            cb(data);
        }, err =>{
            loadEnd();
            cbe(err);
        })
    }
}

export default Admin;