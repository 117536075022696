import { useEffect, useState } from "react";

import { AdminContext } from "../models/admin";

import Layout from "../components/layout";

import '../styles/users.css';





const Content = () =>{

    const [editBalance, setEditBalance] = useState(false);
    const [history, setHistory] = useState(false);
    const [deleteView, setDeleteView] = useState(false);
    const [currentUser, setCurrentUser] = useState('');

    const [usersDetails, setUsersDetails] = useState([]);
    const [outArr, setOutArr] = useState([]);


    let Client = AdminContext().admin;

    const TriggerTableRefresh = () =>{
        const getUsers = async () =>{
            Client.getBasicDetailsOfUsers((users)=>{
                if(users){
                    setUsersDetails(users);
                }
            });
        }
        getUsers();
    }
    useEffect(()=>{
        TriggerTableRefresh();
    },[Client]);

    useEffect(()=>{
        if(usersDetails){
            let _tmp = [];
            usersDetails.forEach(el=>{
                _tmp.unshift(<Entry data={{
                    email : el.email,
                    balance : el.balance,
                    position : el.position,
                    spins : el.spins,
                }}/>);
            });
            setOutArr(_tmp);
        }
    },[usersDetails]);


    // ------------------------------------------------------------


    const Entry = ({data}) =>{
        return(
            <div className="user-entry">
                <span>{data.email}</span>
                <span>{data.balance.toFixed(2)}</span>
                <span>{data.position.toFixed(2)}</span>
                <span>{data.spins} left</span>
                <div className="user-action-cont">

                    <button className="brdls" onClick={()=>{
                        setCurrentUser(data.email);
                        setEditBalance(true);
                    }}>Balance</button>

                    <button className="brdls" onClick={()=>{
                        setCurrentUser(data.email);
                        setHistory(true);
                    }}>History</button>

                    <button className="brdls" onClick={()=>{
                        setCurrentUser(data.email);
                        setDeleteView(true)
                    }}>Delete</button>
                </div>
            </div>
        )
    }

    
    // ------------------------------------------------------------


    const SetBalance = ({props}) =>{
        const [amount, setAmount] = useState(0);
        const [psk, setPsk] = useState();

        const [allowProceed, setAllowProceed] = useState(false);

        useEffect(()=>{
            if(amount && psk){
                setAllowProceed(true);
            }else{
                setAllowProceed(false);
            }
        },[amount,psk]);

        const upadateBalance = (type) =>{
            let fd = new FormData();
            fd.append('email',currentUser);
            fd.append('psk',psk);
            fd.append('amount',amount);
            fd.append('type',type);
            console.log(fd);
            Client.updateUserBalance(fd,data=>{
                console.log(data);
                alert(data.message);
                if(data.status == 0){
                    setEditBalance(false);
                    TriggerTableRefresh();
                }
            },err=>{
                console.log(err);
                alert('An error occured please try again');
            })
        }

        return(
            <div className="ov-base">
                <div className='ov-cont'>
                    <p className="ew-head">Edit Wallet Balance of <span>{currentUser}</span></p>
                    <div className="ew-body">
                        <div className="ew-inp">
                            <p>Amount</p>
                            <input type="number" className="brdls" onChange={(e)=>{
                                setAmount(parseFloat(e.target.value.replace(/[^0-9.-]+/g, "")));
                            }}/>
                        </div>
                        <div className="ew-inp">
                            <p>Passkey</p>
                            <input type="number" className="brdls" onChange={(e)=>{
                                setPsk(e.target.value);
                            }}/>
                        </div>
                        <div className="ew-actions">
                            <div className="ew-req-actions">
                                <button className="brdls" disabled={!allowProceed} onClick={()=>{
                                    upadateBalance(1);
                                }}>Debit</button>
                                <button className="brdls" disabled={!allowProceed} onClick={()=>{
                                    upadateBalance(2);
                                }}> Set as balance</button>
                                <button className="brdls" disabled={!allowProceed} onClick={()=>{
                                    upadateBalance(0);
                                }}>Credit</button>
                            </div>
                            <button className="cancel-ew brdls" onClick={()=>{
                                setEditBalance(false);
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // ------------------------------------------------------------

    const History = () =>{
        const [tr, setTr] = useState([]);
        useEffect(()=>{
            let fd = new FormData();
            fd.append('email',currentUser);
            Client.getUserHistory(fd,data=>{
                if(data.status == 0){
                    let _tmp =[];
                    data.data.forEach(el=>{
                        _tmp.unshift(
                            <Entry data={{
                                description : el.desc,
                                date : el.date,
                                amount : el.amount.toFixed(2)
                            }} />
                        )
                    });
                    setTr(_tmp);
                }else{
                    alert(data.message);
                }
            }, err =>{
                console.log(err);
                alert('An error occured please try again');
            })
        },[])


        const Entry = ({data})=>{
            return(
                <div className="tr-entry">
                    <span>{data.description}</span>
                    <span>{data.date}</span>
                    <span>{data.amount}</span>
                </div>
            )
        }

        return(
            <div className="ov-base">
                <div className='ov-cont'>
                    <p className="ew-head">Wallet History of <span>{currentUser}</span></p>
                    <div className="ew-body">
                        <div className="tr">
                            <div className="tr-head">
                                <span>Description</span>
                                <span>Date</span>
                                <span>Amount</span>
                            </div>
                            <div className="tr-body">
                                {tr}
                            </div>
                            
                        </div>
                    </div>
                    <button className="cancel-ew brdls" onClick={()=>{
                        setHistory(false);
                    }}>Close</button>
                </div>
            </div> 
        )
    }


    // ------------------------------------------------------------

    const Delete = () =>{
        const [psk, setPsk] = useState();
        const [allowProceed, setAllowProceed] = useState(false);

        useEffect(()=>{
            if(psk) setAllowProceed(true);
            else setAllowProceed(false);
        })
        return(
            <div className="ov-base">
                <div className='ov-cont'>
                    <p className="ew-head del">Are you sure to delete <span>{currentUser}</span></p>
                    <div className="ew-body">
                        <div className="ew-inp">
                            <p>Passkey</p>
                            <input type="number" className="brdls" onChange={(e)=>{
                                setPsk(e.target.value);
                            }}/>
                        </div>
                        <div className="ew-req-actions">
                            <button className="brdls" disabled={!allowProceed} onClick={()=>{
                                let fd = new FormData();
                                fd.append('email',currentUser);
                                fd.append('psk',psk);
                                Client.deleteUser(fd,data=>{
                                    alert(data.message);
                                    setDeleteView(false);
                                    TriggerTableRefresh();
                                },err=>{
                                    console.log(err);
                                    alert('An error occured please try again');
                                })
                            }}>Delete User</button>
                        </div>
                    </div>
                    <button className="cancel-ew brdls" onClick={()=>{
                        setDeleteView(false);
                    }}>Cancel</button>
                </div>
            </div>
        )
    }

    // ------------------------------------------------------------

    return(
        <>
        <div className="users-main">
            <div className="users-table">
                <div className="head">
                    <span>
                        Email
                    </span>
                    <span>
                        Balanace
                    </span>
                    <span>
                        Position
                    </span>
                    <span>
                        Spins
                    </span>
                    <span>
                        Actions
                    </span>
                </div>
                <div className="users-body">
                    {outArr}
                </div>
            </div>
        </div>
        {editBalance && <SetBalance />}
        {history && <History />}
        {deleteView && <Delete />}
        <button className="brdls rfsh-table" onClick={TriggerTableRefresh}>Refresh</button>
        </>
    )
}

const Users = () =>{
    return(
        <Layout>
            <Content />
        </Layout>
    )
}

export default Users;